import { useState } from "react";
// import Question from './Question';
// import Logo from './FedFuturist_logo.svg'
import Logo from './FedFuturist_logo_noShadow.svg'
// import Page from './Page';
import CrmSubmit from './CrmSubmit';
import QuestionList from './QuestionList';
import NameForm from "./NameForm";
import CareersResults from "./CareerResults";
// import IdleTimerComponent from "./IdleTimerContainer";
import './Fonts.css'
import "bootstrap/dist/css/bootstrap.min.css";
// import { useIdleTimer } from 'react-idle-timer'
// import { Container } from 'react-bootstrap';
import './App.css';



const initialState = {
  page: 1,
  studyAreas: [
    { id: 1, label: 'Performing arts', range: [14, 28], blurb: '<p>It looks like you\'ll be a star performer on the screen or stage!<br/>If not an actor, could you see yourself in these other careers?</p>', mainCareer: 'an Actor', otherCareers: ['Comedian', 'Performer', 'Playwright', 'Set designer', 'Stage manager', 'Music theatre performer', 'Musician', 'Singer'] },
    { id: 2, label: 'Visual arts', range: [29, 60], blurb: '<p>Get your paint brushes ready, you\'re right on track to La Louvre in Paris!<br/>If not an artist, could you see yourself in these other careers?</p>', mainCareer: 'an Artist', otherCareers: ['Curator', 'Potter/ceramist', 'Printmaker', 'Illustrator', 'Painter', 'Sculptor', 'Animator', 'Graphic designer', 'Gallery assistant']},
    { id: 3, label: 'Education and early childhood', range: [61, 121], blurb: '<p>We think you\'re an inspiration and should be leading and encouraging the next generation!<br/>If not a teacher, could you see yourself in these other careers?</p>', mainCareer: 'a Teacher', otherCareers: ['Day care educator', 'Early childhood educator', 'Kindergarten teacher', 'Preschool teacher', 'Primary teacher Secondary teacher Tutor'] },
    { id: 4, label: 'Arts, criminology and criminal justice', range: [122, 150], blurb: '<p>Your reading, writing and people skills means you could be a success almost anywhere.<br/>If not a writer, we think these careers would suit you:</p>', mainCareer: 'a Writer', otherCareers: ['Community worker', 'Criminologist', 'Editor', 'Marketing and communications assistant', 'Local government officer', 'Entrepeneur', 'Business development manager', 'Communications manager', 'Project coordinator']},
    { id: 5, label: 'Sport, outdoor and physical education', range: [151, 199], blurb: '<p>We think you\'re a real go-getter! <br/>If not a sports scientist, could you see yourself in these other careers?</p>',  mainCareer: 'a Sports Scientist', otherCareers: ['Sport performance Coach', 'Professional athlete', 'Performance analyst', 'Biological scientist', 'Fitness instructor', 'Sport facility manager','Geneticist', 'Chemist']},
    { id: 6, label: 'Nursing, midwifery and paramedicine', range: [200, 308], blurb: '<p>With your social skills and boundless enthusiasm you would be an amazing part of the community.<br/>If not a nurse, could you see yourself in these other careers?</p>', mainCareer: 'a Nurse', otherCareers: ['Community support worker', 'Healthcare worker', 'Aged care worker', 'Disability care worker', 'Midwife', 'Program coordinator', 'Program manager', 'Research manager'] },
    { id: 7, label: 'Science and mathematics', range: [309, 400], blurb: '<p>Nothing gets past you! Your eye for detail means we think you\'d be awesome at making new discoveries!<br/>If not a scientist, could you see yourself in these other careers?</p>', mainCareer: 'a Scientist', otherCareers: ['Park ranger', 'Environmental protection officer', 'Sustainability officer', 'Microbiologist', 'Research scientist', 'Geneticist', 'Chemist', 'Laboratory technician']},
    { id: 8, label: 'Business', range: [401, 490], blurb: '<p>With your instinctive ability to set goals and turn ideas into a reality you would be amazing in the worlds of business and finance!<br/>If not an entrepreneur, could you see yourself in these other careers?</p>', mainCareer: 'an Entrepreneur', otherCareers: ['Accountant', 'Business manager', 'Project manager', 'Marketing manager', 'Systems analyst', 'Public relations manager']},
    { id: 9, label: 'Engineering', range: [491, 600], blurb: '<p>Grab the WD40 and a roll of duct tape \'cause planning, designing and constructing projects is your jam! <br/>If not a Mechanical Engineer, could you see yourself in these other careers?</p>', mainCareer: 'a Mechanical engineer', otherCareers: ['Welder', 'Machinist', 'Boiler maker', 'Electrician', 'CAD drafter', 'Sustainability officer', 'Energy project manager', 'Mining engineer']},
    { id: 10, label: 'Information technology', range: [601], blurb: '<p>Watch out world, we might have the next tech billionaire on our hands! <br/> If not a software engineer, could you see yourself in these other careers?</p>', mainCareer: 'a Software engineer', otherCareers: ['Computer engineer', 'IT consultant', 'Programmer', 'Web designer', 'Database manager', 'Games programmer', 'Multimedia developer', 'Network manager']},

  ],
  questions: [
    {
      id: 1,
      label: 'What\'s your favourite thing to do?',
      answers: [
        { id: 1, label: 'Being outside', score: 100 },
        { id: 2, label: 'Learning new things', score: 49 },
        { id: 3, label: 'Creating', score: 1 },
        { id: 4, label: 'Being with your friends', score: 16 },
        { id: 5, label: 'Setting goals', score: 169 },
        { id: 6, label: 'Playing video games', score: 256 },
      ],
    },
    {
      id: 2,
      label: 'What are your strengths?',
      answers: [
        { id: 1, label: 'Working with your hands', score: 121 },
        { id: 2, label: 'Tech savvy', score: 196 },
        { id: 3, label: 'Team work', score: 64 },
        { id: 4, label: 'Good listener', score: 25 },
        { id: 5, label: 'Creativity', score: 4 },
      ],
    },
    {
      id: 3,
      label: 'How would your friends descibe you?',
      answers: [
        { id: 1, label: 'Independent and practical', score: 255 },
        { id: 2, label: 'Curious about the world', score: 36 },
        { id: 3, label: 'Fun and sometimes dramatic', score: 9 },
        { id: 4, label: 'Warm and friendly', score: 81 },
        { id: 5, label: 'Intense and energetic', score: 144 },
      ],
    },
    {
      id: 4,
      label: 'What is your number one priority for your future career?',
      answers: [
        { id: 1, label: 'Make a lot of money', score: 50 },
        { id: 2, label: 'Benefit the community', score: 30 },
        { id: 3, label: 'Improve science or technological development', score: 30 },
        { id: 4, label: 'Enhance creativity in myself and others', score: 10 }
      ],
    }
  ],
  answers : [],
  score : 0,
  studyAreaResult : null,
  blurbResult : null,
  mainCareer : null,
  chosenCareer : null,
  otherCareersResult : [],
  userName : null,
  lastName: null,
  userEmail : "",
  commencementYear : null,
  schoolFrom: "",
  allAnswered : false,
  coursePrefValue: "* Undecided",
};

function App() {
  const [state, setState] = useState(initialState);

  const answerQuestion = (questionId) => (answerId) => () => {
    //using destructuring 
    const answer = { questionId, answerId };
    const { answers, questions, studyAreas } = state;

    const nextAnswers = answers.filter((a) => a.questionId !== questionId).concat(answer);
    const score = tallyScore(answers, questions);
    const studyAreaObject = findStudyArea(studyAreas, score);
    const studyAreaResult = findStudyAreaName(studyAreaObject)
    const blurbResult = findStudyAreaBlurb(studyAreaObject);
    const otherCareersResult = findStudyAreaOtherCareer(studyAreaObject);
    const mainCareer = findMainCareer(studyAreaObject); 
    
    

    setState({
      ...state,
      answers: nextAnswers,
      score,
      studyAreaResult,
      blurbResult,
      mainCareer,
      otherCareersResult,
      allAnswered: nextAnswers.length === questions.length
    });   
    
   
  };

  //mapping answers to the question to find the score 
  //returns total score for all answers
  const tallyScore = (answer, question) =>{
    return answer.reduce((p, c) => {
      const questions = question.find(q => q.id === c.questionId);
      const answers = questions.answers.find(a => a.id === c.answerId);
      return(p + answers.score)
    }, 0);  
  }

  //find the appropriate studyarea based on the score
  //being in the range of a studyarea
  const findStudyArea = (studyAreas, score) =>{
     const studyArea = studyAreas.find((
      //range = state.studyAreas.range
      { range: [ start, end ] }) => 
      //doing the logic for the range
      score >= start && (!end || score <= end))
      if(!studyArea) return null
      return studyArea
  }

  const findStudyAreaName = (studyArea) => {
    if(!studyArea) return null;
    return studyArea.label;
  }

  const findStudyAreaBlurb = (studyArea) => {
    if(!studyArea) return null;
    return studyArea.blurb;
  }

  const findStudyAreaOtherCareer = (studyArea) => {
    if(!studyArea) return null;
    return studyArea.otherCareers;
  }

  const findMainCareer = (studyArea) => {
    if(!studyArea) return null;
    return studyArea.mainCareer;
  }
  
  document.body.addEventListener('touchstart', function (e) {
    if(e.touches.length > 7) {
      startOver();
    }
  });

  const addEmail = (userEmail, schoolFrom) =>{
    const extraStudyAreas = [{id: 11, label: 'Social work'},{id: 12, label: 'Community and human services'}, {id: 13, label: 'Psychology'}, {id: 14, label: 'Allied Health'}];
    const pagePlus = state.page +1
    setState({
      ...state,
      userEmail,
      schoolFrom,
      page: pagePlus,
      studyAreas: [...state.studyAreas, ...extraStudyAreas],
    });
  }

  const nextPage = () =>{
    const pagePlus = state.page +1
    setState({
      ...state,
      page: pagePlus
    });
  }



  const addNamePlusYear = (userName, lastName, commencementYear) => {
    const pagePlus = state.page +1
    setState({
      ...state,
      userName,
      lastName,
      commencementYear,
      page: pagePlus,
    });
  }

  const startOver = () =>{
    setState(initialState);
  }

  // console.log(state);

  return (
    <div className="fedFuturist-background">
          {state.page === 1 &&
            <div className="fedFuturist-content-area l-intro">
              <div className='header-area'>
                <div className="header-container">
                  <h1><img className="img-fluid" alt="federation futurist logo" src={Logo}></img></h1>
                  <div className="intro mw-650">
                    <h2 className="main-h2">Welcome to the Federation Futurist...</h2>
                    <p className="intro-p pt-3">Explore your future with our interactive career pathfinder</p>
                  </div>
                </div>
              </div>
              <div className="body-area squares">
                <div className="style-squares"></div>
                <div>
                  <button id="tap_to_start" className="btn clear-blue-btn btn-fs-lg" onClick={nextPage}>
                    Tap to start
                    <i className="futurist-arrow"></i>
                  </button>
                </div>
                <div className="style-squares"></div>
                <div className="style-squares"></div>
              </div>
              <div className='footer-area'>
              </div>
            </div>
          }
          {state.page === 2 &&
            <>
              {/* <IdleTimerComponent
              startOver={startOver}
              /> */}
              <div className="fedFuturist-content-area s-intro">
                <div className='header-area'>
                  <div className="header-container">
                    <h1><img className="img-fluid" alt="federation futurist logo" src={Logo}></img></h1>
                  </div>
                </div>
                <div className="body-area squares">
                  <div className="style-squares"></div>
                  <div className="style-squares"></div>
                  <div>
                    <NameForm
                      addNamePlusYear={addNamePlusYear}
                    />
                  </div>
                  <div className="style-squares"></div>
                </div>
                <div className='footer-area'>
                </div>
              </div>
            </>
          }
          {state.page === 3 &&
            <div className="fedFuturist-content-area s-intro no-promo">
              <div className='header-area'>
                <div className="header-container">
                  <h1><img className="img-fluid" alt="federation futurist logo" src={Logo}></img></h1>
                </div>
              </div>
              <div className='body-area'>
                <h2 className="q-name-fs">
                  Hi {state.userName}
                </h2>
                <QuestionList
                  questions={state.questions}
                  answers={state.answers}
                  answerQuestion={answerQuestion}
                />
              </div>
              <div className='footer-area'>
                  <button id="getscore" className={`btn clear-blue-btn btn-fs-md ${state.allAnswered ? "enable" : "disabled"}`} onClick={nextPage}>
                      next
                      <i className="futurist-arrow mini"></i>
                </button>
              </div>
            </div>
          }
          {state.page === 4 &&
            <div className="fedFuturist-content-area s-intro no-promo">
              <div className='header-area'>
                <div className="header-container">
                  <h1><img className="img-fluid" alt="federation futurist logo" src={Logo}></img></h1>
                </div>
              </div>
              <div className='body-area squares'>
                <div className="style-squares"></div>
                <div className="style-squares"></div>
                <CrmSubmit 
                  score={state.score}
                  studyArea={state.studyAreaResult}
                  name={state.userName}
                  lastName={state.lastName}
                  comYear={state.commencementYear}
                  coursePrefValue={state.coursePrefValue}
                  // nextPage={nextPage}
                  addEmail={addEmail}
                />
                <div className="style-squares"></div>
              </div>
              <div className='footer-area'>
                <div className="rights-blrub">
                  <p>Copyright © 2022 Federation University Australia. All rights reserved.</p>
                  <p>By completing this form you are giving consent for Federation University Australia to contact you for marketing purposes.</p>
                  <p>You may change your email preferences or unsubscribe at any time if you no longer wish to receive emails from us.</p>
                </div>
              </div>
            </div>
          }
          {state.page === 5 &&
           <div className="fedFuturist-content-area s-intro no-promo">
              <div className='header-area'>
                <div className="header-container">
                  <h1><img className="img-fluid" alt="federation futurist logo" src={Logo}></img></h1>
                </div>
              </div>
              <div className='body-area'>
                <CareersResults
                  mainCareer={state.mainCareer}
                  otherCareers={state.otherCareersResult}
                  careerBlurb={state.blurbResult}
                  userEmail={state.userEmail}
                  commencementYear={state.commencementYear}
                  lastName={state.lastName}
                  name={state.userName}
                  studyAreas={state.studyAreas}
                  studyAreaResult={state.studyAreaResult}
                  schoolFrom={state.schoolFrom}
                  coursePrefValue={state.coursePrefValue}
                />
              </div>
              <div className="footer-area">
                <div className="restart">
                  <button id="restart_quiz" className="btn clear-blue-btn btn-fs-restart" onClick={startOver}>
                    Restart Quiz
                    <i className="futurist-arrow"></i>
                  </button> 
                </div>
              </div>
              <div className="spotlight spot1"></div>
              <div className="spotlight spot2"></div>
              <div className="spotlight spot3"></div>
              <div className="spotlight spot4"></div>
              <div className="star-pattern star-sm star1"></div>
              <div className="star-pattern star-lg star2"></div>
              <div className="star-pattern star-md star3"></div>
              <div className="star-pattern star-xs star4"></div>
              <div className="star-pattern star-lg star5"></div>
              <div className="star-pattern star-xs star6"></div>
              <div className="star-pattern star-md star7"></div>
              <div className="star-pattern star-xs star8"></div>
              <div className="star-pattern star-md star9"></div>
              <div className="star-pattern star-md star10"></div>
              <div className="star-pattern star-md star11"></div>
              <div className="star-pattern star-sm star12"></div>
              <div className="star-pattern star-lg star13"></div>
              <div className="star-pattern star-md star14"></div>
            </div>
          }
    </div>
  );
}

export default App;
