import { Form } from 'react-bootstrap';
import { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";

function NameForm({ addName, addStartYear, addNamePlusYear }){

    const [name, setName] = useState('');
    const [startYear, setStartYear] = useState('As soon as possible')

    const todaysDate = new Date().getFullYear()
    const commencementLimit = 4 + todaysDate;
    const options = [];


    for(let i=todaysDate;i<=commencementLimit;i++){
        options.push(i)
        if(i === commencementLimit){
            options.unshift("As soon as possible");
            options.push("Unsure");
            
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //need to check if any are empty 
        const error = document.getElementById('no_name');
        const error2 = document.getElementById('no_year');

        var firstName = null , lastName = null, comYear = null;

        if (name !== ''){
            if(error.className === "d-block") { error.className = 'd-none' }
            const splitName = name.split(' ')
            if(!splitName[1]){
                splitName[1] = '.'
            }
            firstName = splitName[0];
            lastName = splitName[1];
        }else{
            
            if(error.className === "d-none") { error.className = 'd-block' }

        }
        if(startYear !== ''){
            // console.log(startYear);
            if(error2.className === "d-block") { error2.className = 'd-none' }
            comYear = startYear;
            // console.log(comYear)
        }else{
            if(error2.className === "d-none") { error2.className = 'd-block' }
        }

        if(firstName && lastName && comYear){
            addNamePlusYear(firstName, lastName, comYear);
        }else{
            return false;
        }
    }

    return(
        <div className='form-container'>
            <p className='py-2 mb-0 fs-tt'>Enter your full name:</p>
            <p id='no_name' className='d-none'>Please enter a name!</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Control 
                    type="name" 
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)}
                    }
                    />
                </Form.Group>
                <p class="pb-2 my-0 fs-tt">Select your commencement year:</p>
                <p id='no_year' className='d-none'>Please enter a commencement year!</p>
                <Form.Group className="mb-5 formBasicSelect">
                    <Form.Select 
                        type="startYear" 
                        value={startYear}
                        onChange={(e) => {
                            setStartYear(e.target.value)}
                        }
                        aria-label="Default select example"
                        defaultValue={options[0]}>
                        {options.map(x => <option key={x} value={x}>{x}</option>)}
                    </Form.Select>
                    <FontAwesomeIcon icon={light("chevron-down")} />
                </Form.Group>
                <button id="begin_quiz" className="btn clear-blue-btn btn-fs-md" variant="primary" type="submit">
                    begin the quiz
                    <i className="futurist-arrow"></i>
                </button>
            </Form>
        </div>
        
    )
    

}

export default NameForm