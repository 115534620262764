import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro'

function Answer({ qId, aId, label, selected, answerQuestion }) {
  const handleAnswerClick = () => answerQuestion();
  return (
    <>
    <Col id={`${qId}_${aId}`} className={`q-question ${selected ? "active" : ""} `} onClick={handleAnswerClick}>
        <p className='q-fs'>{label}</p>
        <span className='check-icon mt-auto'>
          <FontAwesomeIcon icon={duotone('circle-check')} />
        </span>
    </Col>
    </>
  );
}

export default Answer;