import { useEffect } from "react"
import React from "react";

function CrmSubmit({ score, studyArea, name, lastName, comYear, addEmail, schoolFrom, coursePrefValue }){

    // const [userEmail, setUserEmail] = useState('')

    useEffect(() => {

        const crmScript1 = document.createElement('script')
        const crmScript2 = document.createElement('script')

        // PROD 
        crmScript1.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.77.1005'
        crmScript2.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.77.1005'
        

        // UAT
        // crmScript1.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.85.2002'
        // crmScript2.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.85.2002'

        var schoolSet;
        var emailSet;

        crmScript1.addEventListener('load', () => document.body.appendChild(crmScript2))
        crmScript2.addEventListener('load', () => {
            window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function() {
                // for study area click
                const formId_studyArea = 'cdc8773b-e4e1-ea11-a813-000d3a79a22c';
                // for commencment year 
                const formId_comYear = '8bc5818e-a912-ed11-b83d-00224891ebf1';
                // for course preferance
                const formId_coursePref = '1496f58d-4f78-ee11-8179-000d3a794100';

                const firstNameField = document.getElementById('3f746946-34b4-442c-a677-e232cdd2bc40');
                const lastNameField = document.getElementById('e1dfc514-f301-4cb2-855a-4c8fa8331207');

                

                firstNameField.value = name;
                lastNameField.value = lastName;


                window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_studyArea, studyArea)
                    .then(function(response) {
                            document
                                    .getElementById(formId_studyArea)
                                    .nextSibling
                                    .children[0]
                                    .children[0]
                                    .children[0]
                                    .dispatchEvent(new Event('mousedown'));
                    })
                    .catch(function(error) {
                            console.log('Error searching for study area');
                    });

                    window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_comYear, comYear)
                    .then(function(response) {
                            document
                                    .getElementById(formId_comYear)
                                    .nextSibling
                                    .children[0]
                                    .children[0]
                                    .children[0]
                                    .dispatchEvent(new Event('mousedown'));
                    })
                    .catch(function(error) {
                            console.log('Error searching for commencement year' + error);
                    });

                    window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_coursePref, coursePrefValue)
                    .then(function(response) {
                            document
                                    .getElementById(formId_coursePref)
                                    .nextSibling
                                    .children[0]
                                    .children[0]
                                    .children[0]
                                    .dispatchEvent(new Event('mousedown'));
                    })
                    .catch(function(error) {
                            console.log('Error searching for study area');
                    });


	            });

                window.MsCrmMkt.MsCrmFormLoader.on("formSubmit", function(event) {
                    const emailField = document.getElementById('7f685ebb-7c54-4cff-a1bc-772562d25c38');
                    const schoolfild = document.getElementById("6ef5d654-7087-eb11-a812-000d3acbe55b");
                    // console.log(emailField);
                    emailSet = emailField.value;
                    
                    schoolSet = schoolfild.value;
                    // console.log(emailSet);
                    
                    
                });

                window.MsCrmMkt.MsCrmFormLoader.on("afterFormSubmit", function(event) {
                    addEmail(emailSet, schoolSet);
                    
                });
        })
        document.body.appendChild(crmScript1)
        return () => {
            document.body.removeChild(crmScript1)
            document.body.removeChild(crmScript2)
            if (window.MsCrmMkt) window.MsCrmMkt = null;
        }
    }, []);
    return (
      <div id="crm-form">
        {/* PROD FORM */}
        <div data-form-block-id="653ba8a4-08c1-ec11-983e-00224893ad4e"></div> 
        {/* UAT FORM */}
        {/* <div data-form-block-id="503c73f9-cfce-ed11-a7c7-000d3a6ad35b"></div> */}
        {/* PROD FORM */}
        <div id="d4FiQSWadSKMz4iwUfczoBOlJcJWq-tF1z4m31K71Cj4"></div>
        {/* UAT FORM */}
        {/* <div id="dOpzf3yRtCgQ1GJiqlAfHFCmFDe8MCE6wkYUtRkMDMf8"></div> */}
        {/* PROD FORM */}
        <div className="d365-mkt-config" style={{display: "none"}} data-website-id="4FiQSWadSKMz4iwUfczoBOlJcJWq-tF1z4m31K71Cj4" data-hostname="880a01b561b14e368c93297031ca3783.svc.dynamics.com"></div>
        {/* UAT FORM */}
        {/* <div class="d365-mkt-config" style={{ display: "none"}} data-website-id="Opzf3yRtCgQ1GJiqlAfHFCmFDe8MCE6wkYUtRkMDMf8" data-hostname="d71f7b40d7304631a644cea23faed765.svc.dynamics.com"></div> */}
      </div>
    );
}
export default CrmSubmit