import { useEffect } from "react"
import { Form } from 'react-bootstrap';

function CareersResults({ mainCareer, otherCareers, careerBlurb, name, userEmail, lastName, commencementYear, studyAreas, studyAreaResult, addStudyArea, schoolFrom, coursePrefValue}){

    useEffect(() => {

        
        const crmScript1 = document.createElement('script')
        const crmScript2 = document.createElement('script')

        // PROD 
        crmScript1.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.77.1005'
        crmScript2.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.77.1005'

        // UAT
        // crmScript1.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.85.2002'
        // crmScript2.src = 'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.85.2002'
        

        


        crmScript1.addEventListener('load', () => document.body.appendChild(crmScript2))
        crmScript2.addEventListener('load', () => {
            window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function() {
                // console.log(name, score, studyArea)
                // for study area click
                
                // for commencment year 
                const formId_comYear = '8bc5818e-a912-ed11-b83d-00224891ebf1';

                // for course preferance
                const formId_coursePref = '1496f58d-4f78-ee11-8179-000d3a794100';

                const firstNameField = document.getElementById('3f746946-34b4-442c-a677-e232cdd2bc40');
                const lastNameField = document.getElementById('e1dfc514-f301-4cb2-855a-4c8fa8331207');

                const emailFieled = document.getElementById('7f685ebb-7c54-4cff-a1bc-772562d25c38');
                const schoolfield = document.getElementById("6ef5d654-7087-eb11-a812-000d3acbe55b");         



                firstNameField.value = name;
                lastNameField.value = lastName;
                emailFieled.value = userEmail;
                schoolfield.value = schoolFrom;


                    window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_comYear, commencementYear)
                    .then(function(response) {
                            document
                                    .getElementById(formId_comYear)
                                    .nextSibling
                                    .children[0]
                                    .children[0]
                                    .children[0]
                                    .dispatchEvent(new Event('mousedown'));
                    })
                    .catch(function(error) {
                            console.log('Error searching for commencement year' + error);
                    });


                    window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_coursePref, coursePrefValue)
                    .then(function(response) {
                            document
                                    .getElementById(formId_coursePref)
                                    .nextSibling
                                    .children[0]
                                    .children[0]
                                    .children[0]
                                    .dispatchEvent(new Event('mousedown'));
                    })
                    .catch(function(error) {
                            console.log('Error searching for study area');
                    });


	            });
        })
        document.body.appendChild(crmScript1)
        return () => {
            document.body.removeChild(crmScript1)
            document.body.removeChild(crmScript2)
            if (window.MsCrmMkt) window.MsCrmMkt = null;
        }
    }, []);

    const updateSelection = (e) => {
        const formId_studyArea = 'cdc8773b-e4e1-ea11-a813-000d3a79a22c';
        const newStudySelection = e.target.value;

        window.MsCrmMkt.MsCrmFormLoader.fillLookupFromSearch(formId_studyArea, newStudySelection)
            .then(function(response) {
                    document
                            .getElementById(formId_studyArea)
                            .nextSibling
                            .children[0]
                            .children[0]
                            .children[0]
                            .dispatchEvent(new Event('mousedown'));

            })
            .catch(function(error) {
                    console.log('Error searching for study area');
            });
    }

    const submitCRMForm = () => {
        let checkStudyArea = document.querySelector('.update-studyArea').value 
        if(studyAreaResult === checkStudyArea){
            // console.log("same study area")
            document.querySelector('.chng-error').classList.remove("d-none")
            document.querySelector('.chng-error').classList.add("d-block")
        }else{
            document.getElementById("crm_submit").click() 
        }

        window.MsCrmMkt.MsCrmFormLoader.on("afterFormSubmit", function(event) {
            let remove_update_text =  document.querySelectorAll(".before_update")
            
            remove_update_text.forEach((x) => {
                x.classList.add("d-none")
            })
            document.querySelector(".after_update").classList.remove('d-none')
            document.querySelector(".after_update").classList.add('d-block')

        });
    }


    return (
      <>
        <div className="main-result">
          <div className="m-container">
            <p className="fs-tt mb-0">We think you'll be...</p>
            <h2 className="result-fs mb-0">{mainCareer}!</h2>
          </div>
        </div>

        <div className="px-3 py-3 result-area">
          <div className="m-container">
            <h4 className="result-name-fs">Congratulations {name}</h4>
            {/* remove in future */}
            <div
              className="result-blurb-fs"
              dangerouslySetInnerHTML={{ __html: `${careerBlurb}` }}
            />
            <ul className="result-list">
              {otherCareers.map((career) => (
                <li key={career}>{career}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="resultMessage m-container">
          {/* <p>Thanks for your submission!</p> */}
          {/* <p>Your future has been revealed and results have been emailed.</p> */}
          <div class="row row justify-content-center align-items-center pb-3">
            <div className="col-12 before_update mb-3">
              <p>
                If you would like to change your chosen result, <br/>please select
                one from the drop down and hit update
              </p>
            </div>
            <div className="col-12 col-md-10 col-lg-10 before_update">
              <Form.Select
                className="update-studyArea"
                type="updateStudyArea"
                value={studyAreas.label}
                onChange={updateSelection}
                defaultValue={studyAreaResult}
              >
                {studyAreas.map((area) => (
                  <option key={area.label} value={area.label}>
                    {area.label}
                  </option>
                ))}
              </Form.Select>
            </div>
            <button
              className="col-auto mb-2 mb-lg-0 mt-3 btn clear-blue-btn btn-fs-xxs before_update update-fs-btn"
              onClick={submitCRMForm}
            >
              Update
              <i class="futurist-arrow"></i>
            </button>
            <div className="col-12 col-md-8 chng-error before_update d-none">
              <p>
                Please select a different Study Area to the one you have been
                chosen
              </p>
            </div>
            <div className="after_update d-none">
              <p>Thank you, your prefered study are has been updated</p>
            </div>
          </div>
          <p class="mt-md-3">
            For more information on how to kick-start your career at Federation
            University, speak to one of our course advisors or jump on our
            website.
          </p>
          <p>We look forward to you studying with us soon.</p>
        </div>

        <div id="crm-form" style={{display: "none"}}>
          {/* PROD FORM */}
          <div data-form-block-id="653ba8a4-08c1-ec11-983e-00224893ad4e"></div>
          {/* UAT FORM */}
          {/* <div data-form-block-id="503c73f9-cfce-ed11-a7c7-000d3a6ad35b"></div> */}
          {/* PROD FORM */}
          <div id="d4FiQSWadSKMz4iwUfczoBOlJcJWq-tF1z4m31K71Cj4"></div>
          {/* UAT FORM */}
          {/* <div id="dOpzf3yRtCgQ1GJiqlAfHFCmFDe8MCE6wkYUtRkMDMf8"></div>*/}
          {/* PROD FORM */}
          <div className="d365-mkt-config" style={{display: "none"}} data-website-id="4FiQSWadSKMz4iwUfczoBOlJcJWq-tF1z4m31K71Cj4" data-hostname="880a01b561b14e368c93297031ca3783.svc.dynamics.com"></div>
          {/* UAT FORM */}
          {/* <div class="d365-mkt-config" style={{ display: "none" }} data-website-id="Opzf3yRtCgQ1GJiqlAfHFCmFDe8MCE6wkYUtRkMDMf8"data-hostname="d71f7b40d7304631a644cea23faed765.svc.dynamics.com"></div> */}
        </div>
      </>
    ); 
    
    
    
}

export default CareersResults;
