import Question from "./Question"

function QuestionList({ questions, answers, answerQuestion }) {
  return questions.map(({ id, label, answers: questionAnswers }) => {
    const answer = answers.find(a => a.questionId === id)
    const answerId = answer && answer.answerId

    // Previous Answer
    const previousAnswer = answers.find(a => a.questionId === (id - 1));
    var canClick = false;
    if((!previousAnswer && id === 1) || previousAnswer){
      canClick = true;
    }
    
    return (
      <Question
        key={id}
        questionId={id}
        label={label}
        answers={questionAnswers}
        answerId={answerId}
        answerQuestion={answerQuestion(id)}
        canAnswer={canClick}
      />
    )
  })
}

export default QuestionList