import Answer from "./Answer";
import { Container, Row, Col} from 'react-bootstrap';

function Question({ questionId, label, answers, answerId, answerQuestion, name, canAnswer }) {
  return (
    <>
    <div className={`question-wrapper ${canAnswer ? "answered" : ""} py-3`}>
      <Container fluid className="g-0">
        <Row className="g-0">
          <Col xs={12}>
            <div className="q-header">
              <h4 className="fs-tt" style={{marginBottom: '0px'}}>{label}</h4>
            </div>
          </Col>
          {/* <div > */}
            {answers.map(({ id, label }) =>
              <Answer key={id} aId={id} qId={questionId} label={label} selected={answerId === id} answerQuestion={answerQuestion(id)} />
            )}
          {/* </div> */}
        </Row>
      </Container>
    </div>
    </>
    
  );
}

export default Question;